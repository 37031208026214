module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Efficiam website","short_name":"Efficiam","start_url":"/","background_color":"#100c19","theme_color":"#100c19","display":"minimal-ui","icon":"/home/runner/work/efficiam-website-gatsby/efficiam-website-gatsby/src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"107f4f5bb06985063659eafe464544be"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/runner/work/efficiam-website-gatsby/efficiam-website-gatsby/src/i18n","languages":["en","it"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
